<template>
    <div class="py-5 px-auto">
      <span :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{label}}</strong></span>
      <v-btn
      v-if="!hideDownloadPDF"
              :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
                outlined
                small
                @click="downLoadPDF()">
            Download PDF
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-adobe-acrobat
      </v-icon>
        </v-btn>
        <canvas  ref="canvas"></canvas>
    </div>
</template>

<script>
import {Bar } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'


export default {
    name:'BarGraphByLabel',
    extends: Bar ,
    props:['label','graphData','dataKey','labelKey','unit','timeUnit','stopDownload','hideDownloadPDF'],
    components:{
        
    },
   
    computed:{
        dateLabels(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.labelKey])
            }
            return l
        },
        consumed(){
            let l=[]
            //console.log(this.$store.state.last7Days)
            for (let i of this.graphData){
                l.push(i[this.dataKey])
            }
            
            return l
        },
        yAxisLabel(){
          if(this.unit && this.label){
            return this.label +" - "+ this.unit
          }else if(this.unit){
            return this.unit
          }else if(this.label){
            return this.label
          }else{
            return ''
          }
        }
    },
    data(){
        return {
         colorCode:this.$store.getters.getColorPalette().colorTypesCodeArray,
         tickfontColor:this.$store.getters.getColorPalette().fontcolorTypescodes,
        }
    },
    methods: {
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);
        this.$emit('imageData',{
          imageData:pageData,
          height:canvas.height,
          width:canvas.width
        })
        if(this.stopDownload){
          return
        }

                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  pdf.text(40, 40, this.label)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.label+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
      },
        createChart(){
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dateLabels,
          datasets: [
            {
                barPercentage: 0.9,
        //barThickness: 6,
        maxBarThickness: 81,
                label:this.label,
               backgroundColor:  this.colorCode[0],
               borderColor:'rgba(220, 25, 200,0.5)',
               pointBorderColor: 'rgba(20, 25, 100,0.5)',
               pointBackgroundColor: 'rgba(255,255,255,1)',
                //pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 4,
                
                //data: [119, 40,60,34,100,20,10],
                data: this.consumed,
                fill:true,
                lineTension:0.1
            }
          ]},{responsive: true, maintainAspectRatio: true, aspectRatio:3, legend:{display: false},
          scales: {
        xAxes: [ {
          //type: 'linear',
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Date'
          },
          ticks: {
               fontColor:this.$store.getters.getColorPalette().tickfontColor,
            major: {
              fontStyle: 'bold',
                 fontColor:this.$store.getters.getColorPalette().tickfontColor,
            },
            source:'auto'
          },
        gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        },
         ],
        yAxes: [ 
          {
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.yAxisLabel,
                fontColor:this.$store.getters.getColorPalette().tickfontColor,
          },
           gridLines: {
          display: true,
          color: "rgba(165, 165, 165,0.6)"
        },
        } ]
      }})
        }
    },
    mounted() {

        this.createChart()
        
    },
    watch: {
        consumed:function(){
      
      this.createChart()

        }
    },
}
</script>
<style >

</style>