<template>
    <div class="my-5 mx-4">
<h1 class="text-subtitle-2 text-capitalize grey--text upper"> Data</h1>
<v-container fluid>
    <v-row>
        <v-col align="right">
            <v-btn   fab :dark="$store.getters.getColorPalette().isDark" color="blue" @click="dialog=!dialog" >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
        </v-col>
    </v-row>
    <v-row v-if="dialog">
        <v-col cols="12">
            <v-card>
                <DataUpload />
            </v-card>
            
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <EbillDataPage/>
        </v-col>
    </v-row>
</v-container>
    </div>
</template>
<script>
import DataUpload from '@/components/crud_components/DataUpload'
import EbillDataPage from '@/pages/EbillDataPage'
export default {
    name:'Data',
    components:{
        DataUpload,
        EbillDataPage
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
            this.$router.push("/");
            }
    },
    data(){
        return {
            dialog:false
        }
    }
    
}
</script>