<template>
    <v-container fluid>
     <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
<v-form ref="form" v-model="valid">
    <v-row>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="form.consumer_id"
      :counter="20"
      
      :label="$store.getters.getTextMap().consumer_ID"
      required
    ></v-text-field>
    </v-col>
    
    <v-col>
        <v-checkbox
          v-if="!loading"
      v-model="dateRange"
      :label="$store.getters.getTextMap().date_range"
    ></v-checkbox>
    </v-col>
        <v-col>
            <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
           {{ $store.getters.getTextMap().get_data}}
          </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col v-if="dateRange">
            <v-select
    v-if="!loading"
          v-model="form.dateParam"
          :items="dateParamOptions"       
          :label="$store.getters.getTextMap().value"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>
        </v-col>
        <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    </v-row>
</v-form>

    <v-row v-if="graphData && graphData.length>0">
        <v-col align-self="center">
            <v-simple-table  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center"
          v-for="item in headers"
          :key="item">
            <strong>{{item}}</strong>
          </th>
          
          
         
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in graphData"
          :key="item.bill_issue_date"
        >
          <td
          class="text-center"
          v-for="head in headValues"
          :key="head">{{item[head]}}</td>
          
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-col>
    </v-row>
    
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
//import BarGraphByLabel from '@/components/graphs/BarGraphByLabel'
import axios from 'axios'
export default {
    name:'DataGroupingPanel',
    components:{
        InfoAlert,
        //BarGraphByLabel
    },
    mounted(){},
    data(){
        return {
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            graphData:[],
            dateRange:false,
            menu1:null,
            menu2:null,
            form:{
            },
            report:{},
            headers:[
                'Site ID', 'Consumer ID', 'Meter No (KWH)', 'Name On Bill',
                'Address', 'Bill No','Bill Issue Date',
                'Bill Due Date', 'Meter Consumption Unit (KWH)', 'Total Units', 
                'Bill Amount'
            ],
            headValues:[
                'site_id', 'consumer_id', 'meter_no_kwh', 'name_on_bill',
                'address', 'bill_no','bill_issue_date',
                'bill_due_date', 'meter_consumption_unit_kwh', 'total_unit', 
                'bill_amount'
            ],
            /*{
                'Site ID':'site_id', 'Consumer ID':'consumer_id', 'Meter No (KWH)':'meter_no_kwh', 'Name On Bill':'name_on_bill',
                'Address':'address', 'Bill No':'bill_no','Bill Issue Date':'bill_issue_date',
                'Bill Due Date':'bill_due_date', 'Meter Consumption Unit (KWH)':'meter_consumption_unit_kwh', 'Total Units':'total_unit', 
                'Bill Amount':'bill_amount'
            }, */
            dateParamOptions:[
                //{label:'Billed Unit',value:'billed_unit'},
                {label:'Bill Due Date',value:'bill_due_date'},
                {label:'Bill Issue Date',value:'bill_issue_date'},
                {label:'Bill Receive Date',value:'bill_receive_date'},
            ],
            byOptions:[
                {label:'Regions',value:'region'},
            ],
            objectRules:[
                (v)=>!!v || 'required'
            ]
        }
    },
    methods:{
        onSubmit(){
            this.$refs.form.validate()
            if(this.valid){

                this.loading=true
                //let fromTimeStamp=this.fromTimeStamp
                //let toTimeStamp=this.toTimeStamp
                
                let endpoint='getDataByConsumerID'
                //console.log(fromTimeStamp)
                let payload={
                    consumer_id:this.form.consumer_id
                }
                if(this.dateRange){
                    payload['to_date']=this.form.to_date
                    payload['from_date']=this.form.from_date
                    payload['dateParam']=this.form.dateParam
                }
                axios.post(this.$store.state.api+endpoint,this.form,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                //console.log("report data received")
                //console.log(response.data)
                if(response.data.status==='success'){
                    this.report=Object.assign({},this.form)
                    this.graphData=response.data.data
                }else{
                    this.info = response.data.msg;
                //this.info = d;
                        this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                //this.loading=false
                }
                this.loading=false
                }).catch(err=>{
                    this.showDismissibleAlert=true
                    this.info=err
                    this.loading=false
                })
            }
        },
    }
}
</script>