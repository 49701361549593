<template>
    <v-container fluid>
        
        <v-row>
            <v-col cols="12" align-self="center">
                <v-card class="pa-2">
                <RegionDetails />
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col>
                <v-card class="pa-2">
                    <v-card-title>
                        View By Consumer ID
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <FetchConsumerData />
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col>
                <v-card class="pa-2">
                    <v-card-title>
                        View By Site ID
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <FetchSiteData />
                    </v-card-subtitle>
                
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align-self="center">
                <v-card class="pa-2">
                    <v-card-title>
                        View By
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <DataGroupingPanel />
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DataGroupingPanel from '@/components/panels/DataGroupingPanel'
import FetchConsumerData from '@/components/crud_components/FetchConsumerData'
import FetchSiteData from '@/components/crud_components/FetchSiteData'
import RegionDetails from '@/components/panels/RegionDetails'
export default {
    name:'EbillDataPage',
    components:{
        DataGroupingPanel,
        FetchConsumerData,
        FetchSiteData,
        RegionDetails
    }
}
</script>