<template>
    <div class="py-5 px-auto">
      <span  :style="{color:$store.getters.getColorPalette().accentCode}"><strong>{{label}}</strong></span>
      <v-btn
               :color="$store.getters.getColorPalette().downloadbtnColor"
                class="ma-2 white--text"  
              outlined
                small
                @click="downLoadPDF()">
            Download PDF
      <v-icon
        right
        :dark="$store.getters.getColorPalette().isDark" 
      >mdi-adobe-acrobat
      </v-icon>
        </v-btn> 
        <canvas  ref="canvas"></canvas>
    </div>
</template>

<script>
import {Bar } from 'vue-chartjs'
import moment from 'moment'
import jsPDF from 'jspdf'
//import ColorUtility from '@/utillities/ColorUtility'

export default {
    name:'MultiAxisBarGraphByLabel',
    extends: Bar ,
    props:['xLabelKey','label','graphData','dataKeys','unit'],
    components:{
        
    },
    computed:{
        dataLabels(){
            let l =[]

            if(this.graphData && this.xLabelKey){
                for(let i of this.graphData){
                    l.push(i[this.xLabelKey])
                }
            }
            return l
            //return ['1','2','3','4']
        },
        
        dataSets(){
            let ds=[]
            let c=11
            let r=c
            let y=c
            let b=c
            let count=0
            for(let i of this.dataKeys){
                let l=[]
                
                for (let g of this.graphData){
                    l.push(g[i])
                }
                // r=(c*5)%255
                // y=(c*3)%255
                // b=((c*7)%255)
                ds.push({
                label:i,
                barPercentage: 0.9,
        //barThickness: 6,
        maxBarThickness: 81,
               backgroundColor: this.colorCode[count]+'5F',//'rgba('+(r-9)+', '+(y-9)+', '+(b-9)+',0.2)',//'#90A4AE',
               borderColor:this.colorCode[count],//'rgba('+(r-2)+', '+(y-1)+', '+(b-3)+',0.9)',
               pointBorderColor: 'rgba('+r+', '+y+', '+b+',0.7)',
               pointBackgroundColor: this.$store.getters.getColorPalette().accentCode,//'rgba(255,255,255,1)',
                pointRadius:1,
                borderWidth: 2,
                hoverBorderWidth: 5,
                //data: [119, 40,60,34,100,20,10],
                data: l,
                fill:true,
                lineTension:0,
                yAxisID: "y-axis-"+i
            })
            count++
            // c+=27
            }
            return ds

        },
        yAxisLabel(){
          if(this.unit && this.label){
            return this.label +" - "+ this.unit
          }else if(this.unit){
            return this.unit
          }else if(this.label){
            return this.label
          }else{
            return ''
          }
        },
        yAxes(){
            let y=[]
            let c=1
            for(let g of this.dataKeys){
               y.push({
              type: 'linear',
              display: true,
                gridLines: {
              display: true,
          fontColor:this.$store.getters.getColorPalette().tickfontColor,//"rgba(165, 165, 165,0.6)"
            },
          position:c%2==0?"right":"left",
          id:"y-axis-"+g,
          scaleLabel: {
            fontColor:this.$store.getters.getColorPalette().tickfontColor,//'rgba(165, 165, 165,1)',
            display: true,
            fontSize: 15,
            autoFit: true,
            wrap: true,
            maxHeight: 300,
            labelString:  g
          },
          ticks:{
            fontColor:this.$store.getters.getColorPalette().tickfontColor,
            beginAtZero: this.beginAtZero,
            callback: function(value) {
                          var ranges = [
                              { divider: 1e6, suffix: 'M' },
                              { divider: 1e3, suffix: 'k' }
                          ];
                          function formatNumber(n) {
                              for (var i = 0; i < ranges.length; i++) {
                                if (n >= ranges[i].divider) {
                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                }
                              }
                              return n;
                          }
                          return formatNumber(value)  //'$' + formatNumber(value);
                        }
                      }
            })
            c++
            
            }
        return y
        }
    },
    data(){
        return {
          colorCode:this.$store.getters.getColorPalette().colorTypesCodeArray,
          // tickfontColor:this.$store.getters.getColorPalette().fontcolorTypescodes,
        }
    },
    methods: {
      downLoadPDF(){

        let canvas=this.$refs.canvas
        let pageData = canvas.toDataURL('image/png', 1.0);

                  //Default vertical direction, size ponits, format a4[595.28,841.89]
                  //var pdf = new jsPDF('', 'pt', 'a4');
                  //let pdf = new jsPDF('', 'pt', 'a4');
                  let pdf = new jsPDF('landscape','pt',[canvas.width+50, canvas.height+150]);
                  pdf.setFontSize(35)
                  pdf.text(40, 40, this.label)
                  //Two parameters after addImage control the size of the added image, where the page height is compressed according to the width-height ratio column of a4 paper.
                  //pdf.addImage(pageData, 'JPEG', 0, 10, 595.28, 592.28/canvas.width * canvas.height );
                  pdf.addImage(pageData, 'JPEG', 0, 150, canvas.width,canvas.height );

                  pdf.save(this.label+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
      },
        createChart(){
            this.renderChart({
          //labels: ['monday', 'tuesday', 'wednesday', 'thursday','friday','saturday','sunday'],
          labels: this.dataLabels,
          datasets: this.dataSets},
          {responsive: true, maintainAspectRatio: true, aspectRatio:4, legend:{display: true, labels:{
          fontColor:this.$store.getters.getColorPalette().tickfontColor,
          }},
          scales: {
        xAxes: [ {
          
          display: true,
            gridLines: {
          display: true,
       fontColor:this.$store.getters.getColorPalette().tickfontColor,//"rgba(165, 165, 165,0.6)"
        },
          scaleLabel: {
         fontColor:this.$store.getters.getColorPalette().tickfontColor,//'rgba(165, 165, 165,1)',
            display: false,
            labelString: 'label'
          },
          ticks: {
        fontColor:this.$store.getters.getColorPalette().tickfontColor,//'rgba(165, 165, 165,1)',
            major: {
              fontStyle: 'bold',
           fontColor:this.$store.getters.getColorPalette().tickfontColor,//'#FF0000'
            },
            source:'auto',
            autoSkipPadding:10
          }
        } ],
        yAxes: this.yAxes
      }})
        }
    },
    mounted() {

        this.createChart()
        
    },
    watch: {
        graphData:function(){
      
      this.createChart()

        }
    },
}
</script>