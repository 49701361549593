<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-form ref="form" v-model="valid">
        <v-row>
        <v-col cols="10">
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-file-input
            v-if="!loading"
            v-model="form.myfile"
            :rules="fileRules"
            accept="text/csv"
            placeholder="Choose a CSV file"
            prepend-icon="mdi-clipboard-arrow-up"
            :label="$store.getters.getTextMap().CSV"
            small
        ></v-file-input>
        </v-col>
        <v-col align-self="center" align="right">
            <v-btn v-if="!loading" @click="onSubmit" small >{{$store.getters.getTextMap().upload}}</v-btn>
        </v-col>
        </v-row>
        
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'DataUpload',
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{},
            valid:false,
            loading:false,
            info:null,
            showDismissibleAlert:false,
            fileRules:[
        v=> (v!=null) || 'Required'
      ],
        }
    },
    methods:{
        onSubmit(){
            //evt.preventDefault();
            this.$refs.form.validate()
            if(this.valid){
            this.loading = true;
            let formData = new FormData();
            formData.append('csv',this.form.myfile)
      axios.post(this.$store.state.api + "csvUpload", formData,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt,
      'Content-Type': 'multipart/form-data'
    }})
        .then(response => {
          if (response.data.status == "success" && response.data.list) {
            
            
            
            
           
            this.info = "File Uploaded";
          this.showDismissibleAlert = true;
          this.loading = false;
            this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.form.reset()
            this.$emit('close')
            
            
        }
    }
}
</script>