<template>
<v-container fluid>
     <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
     
<v-form ref="form" v-model="valid">
    <v-row>
        
        <v-col>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        <v-checkbox
          v-if="!loading"
      v-model="dateRange"
      :label="$store.getters.getTextMap().date_range"
    ></v-checkbox>
    </v-col>
       
        <v-col v-if="dateRange">
            <v-select
    v-if="!loading"
          v-model="form.dateParam"
          :items="dateParamOptions"       
          :label="$store.getters.getTextMap().value"
         :rules="objectRules"
         required
          item-text="label"
          item-value="value"
          clearable
        ></v-select>
        </v-col>
        <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            :label="$store.getters.getTextMap().from_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.from_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col v-if="dateRange">
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
     <v-col v-if="dateRange" align-self="center">
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            small
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().generate_trend}}

          </v-btn>
        </v-col>
        <v-col>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-select
    v-if="!loading"
          v-model="form.valueParam"
          :items="valueParamOptions"       
          :label="$store.getters.getTextMap().value"
         
         required
          item-text="label"
          item-value="value"
          
        ></v-select>
        </v-col>
    </v-row>
    
    <v-row v-if="graphData && graphData.length>0">
        <v-col align-self="center">
            <v-simple-table  height="350px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().region}}
</strong>
          </th>
          <th class="text-right">
            <strong>{{$store.getters.getTextMap().total_unit}}
</strong>
          </th>
          <th class="text-right">
            <strong>{{$store.getters.getTextMap().bill_amount}}
</strong>
          </th>
          <th class="text-right">
            <strong>{{$store.getters.getTextMap().meter_consumption_kwh}}
</strong>
          </th>
          
         
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in graphData"
          :key="item['region']"
          @click="getRegionMonthlyData(item['region'])"
        >
          <td>{{ item['region'] }}</td>
          <td class="text-right">{{item['total_unit']}}</td>
          <td class="text-right">{{item['bill_amount']}}</td>
          <td class="text-right">{{item['meter_consumption_unit_kwh']}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-col>
        <v-col align-self="center">
            <v-card class="pa-2">
            <BarGraphByLabel :graphData="graphData" :label="form.valueParam" :dataKey="form.valueParam" labelKey="region"  />
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="regionData && regionData.length>0">
        <v-col align-self="center">
            <v-card class="pa-2">
            <MultiAxisBarGraphByLabel :graphData="regionData" :label="region + ' - Monthly Usage'" :dataKeys="['meter_consumption_unit_kwh','bill_amount','total_unit']" xLabelKey="bill_due_date"  />
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="regionData && regionData.length>0">
      <v-col>
        <v-data-table
      :headers="headers"
      :items="regionData"
      item-key="timestamp"
      :search="search"
      :single-expand="true"
      
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{region}} - {{$store.getters.getTextMap().monthly_usage}}
</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn 
                v-if="graphData.length>0"
                outlined 
               :color="$store.getters.getColorPalette().downloadbtnColor"
                @click="downloadPDF">
                {{$store.getters.getTextMap().download_table}}

                </v-btn>
        <a ref="csvDownload" hidden></a>
      </v-toolbar>
          </template>
          
          
          
    </v-data-table>
      </v-col>
    </v-row>

</v-form>

</v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import BarGraphByLabel from '@/components/graphs/BarGraphByLabel'
import MultiAxisBarGraphByLabel from '@/components/graphs/MultiAxisBarGraphByLabel'
import axios from 'axios'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
export default {
    name:'DataGroupingPanel',
    components:{
        InfoAlert,
        BarGraphByLabel,
        MultiAxisBarGraphByLabel
    },
    mounted(){
        this.onSubmit()
    },
    data(){
        return {
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            graphData:[],
            dateRange:false,
            menu1:null,
            menu2:null,
            region:null,
            regionData:[],
            form:{
                by:'region',
                valueParam:'total_unit'
            },
            report:{},
            search:null,
            headers:[
              {
            text: 'Bill Due Date',
            align: 'start',
            filterable: false,
            value: 'bill_due_date',
          },
         // {text:'timestamp',value:'timestamp'},
          { text: 'Total Units', value: 'total_unit' },
          { text: 'Meter Consumption Unit (KWH)', value: 'meter_consumption_unit_kwh' },
          { text: 'Bill Amount', value: 'bill_amount' },
            ],
            valueParamOptions:[
                //{label:'Billed Unit',value:'billed_unit'},
                {label:'Total Unit',value:'total_unit'},
                {label:'Meter Consumption Unit (KWH)',value:'meter_consumption_unit_kwh'},
                {label:'Bill Amount',value:'bill_amount'},
            ],
            byOptions:[
                {label:'Regions',value:'region'},
                {label:'Board Name',value:'board_name'},
            ],
            dateParamOptions:[
                //{label:'Billed Unit',value:'billed_unit'},
                {label:'Bill Due Date',value:'bill_due_date'},
                {label:'Bill Issue Date',value:'bill_issue_date'},
                {label:'Bill Receive Date',value:'bill_receive_date'},
            ],
            objectRules:[
                (v)=>!!v || 'required'
            ]
        }
    },
    methods:{
      downloadPDF(){

          //let pdf = new jsPDF('landscape','pt',[512,600]);
            let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Month',"Total Units","Bill Amount","Meter Consumption (KWH)"]
            
            let body=[]
            for (let i of this.regionData){
                let temp=[]
                temp.push(i.bill_due_date)
                temp.push(i.total_unit)
                temp.push(i.bill_amount)
                temp.push(i.meter_consumption_unit_kwh)
                
                
                body.push(temp)
            }
            pdf.autoTable({
                head: [header],
                body: body
                })
            pdf.save(this.region+'.pdf');

            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.region + '.csv';
            hiddenElement.click();

      },
        getRegionMonthlyData(region){
            this.region=region
            this.loading=true
                //let fromTimeStamp=this.fromTimeStamp
                //let toTimeStamp=this.toTimeStamp
                let payload={
                    by:'bill_due_date',
                    //valueParams:['meter_consumption_unit_kwh','bill_amount','total_unit'],
                    region:this.region
                    }
                let endpoint='ebillMonthlyConsumption'
                //console.log(fromTimeStamp)
                if(this.dateRange){
                    payload['to_date']=this.form.to_date
                    payload['from_date']=this.form.from_date
                    payload['dateParam']=this.form.dateParam
                }
                axios.post(this.$store.state.api+endpoint,payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                //console.log("report data received")
                //console.log(response.data)
                if(response.data.status==='success'){
                    //this.report=Object.assign({},this.form)
                    this.regionData=response.data.data
                    this.regionData.sort((a,b)=>{return moment(a['bill_due_date'],'MMM YYYY').unix()-moment(b['bill_due_date'],'MMM YYYY').unix()})
                }else{
                    this.info = response.data.msg;
                //this.info = d;
                        this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                //this.loading=false
                }
                this.loading=false
                }).catch(err=>{
                    this.showDismissibleAlert=true
                    this.info=err
                    this.loading=false
                })
        },
        onSubmit(){
            this.$refs.form.validate()
            if(this.valid){

                this.loading=true
                //let fromTimeStamp=this.fromTimeStamp
                //let toTimeStamp=this.toTimeStamp
                let payload={
                    by:'region',
                    valueParams:['meter_consumption_unit_kwh','bill_amount','total_unit'],
                    }
                let endpoint='ebillGroupBy'
                //console.log(fromTimeStamp)
                if(this.dateRange){
                    payload['to_date']=this.form.to_date
                    payload['from_date']=this.form.from_date
                    payload['dateParam']=this.form.dateParam
                }
                axios.post(this.$store.state.api+endpoint,payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                //console.log("report data received")
                //console.log(response.data)
                if(response.data.status==='success'){
                    this.report=Object.assign({},this.form)
                    this.graphData=response.data.data
                }else{
                    this.info = response.data.msg;
                //this.info = d;
                        this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                //this.loading=false
                }
                this.loading=false
                }).catch(err=>{
                    this.showDismissibleAlert=true
                    this.info=err
                    this.loading=false
                })
            }
        },
    }
}
</script>